// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attributions-js": () => import("./../../../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-dato-cms-article-slug-js": () => import("./../../../src/pages/posts/{datoCmsArticle.slug}.js" /* webpackChunkName: "component---src-pages-posts-dato-cms-article-slug-js" */),
  "component---src-pages-posts-dato-cms-project-slug-js": () => import("./../../../src/pages/posts/{datoCmsProject.slug}.js" /* webpackChunkName: "component---src-pages-posts-dato-cms-project-slug-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

